import React from "react";
import Toolbar, {Props as ToolbarProps} from "../Toolbar/Toolbar";
import "./VideoPlayer.css";
import {VideoSeeker} from "./VideoSeeker/VideoSeeker";
import {CollectService} from "../../services/collect.service";

type Props = {
    videoElement?: HTMLVideoElement;
    isPlaying?: boolean;
    /**
     * Show video controls
     */
    showControls?: boolean;
    onVideoEnd?: () => void;
    onPlay?: () => void;
    onPause?: (time?: number) => void;
    instructions?: string;
    activationId: string;
};

type State = {
    playing: boolean;
    fullscreen: boolean;
    videoCurrentTime?: number;
};

export class VideoPlayer extends React.Component<Props, State> {
    private videoElement?: HTMLVideoElement;
    private collectService?: CollectService;

    constructor(props: Props) {
        super(props);
        this.state = {
            fullscreen: false,
            playing: false,
        };
    }

    componentDidMount() {
        this.collectService = new CollectService();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        // Video element changed
        if (prevProps.videoElement !== this.props.videoElement) {
            this.initVideoElement(this.props.videoElement);
        }

        // If player is in fullscreen mode, outside control is not available
        if (!this.state.fullscreen) {
            if (this.props.isPlaying) {
                this.playVideo();
            } else {
                this.pauseVideo();
            }
        }
    }

    detectChange<K extends keyof Props>(prevProps: Readonly<Props>, prop: K) {
        const prevValue = prevProps[prop];
        const currentValue: Props[K] = this.props[prop];
        const firstChange: boolean = prevValue === undefined;
        const valueChanged: boolean = prevValue !== currentValue;
        return [currentValue, firstChange, valueChanged];
    }

    render() {
        const {playing, fullscreen} = this.state;

        const showControls = this.props.showControls || fullscreen;
        const toolbarProps: ToolbarProps = {
            showControls,
            isPlaying: playing,
            isFullscreen: fullscreen,
            instructions: this.props.instructions,
            onPause: () => {
                this.onPause();
            },
            onPlay: () => {
                this.onPlay();
            },
            onMinimize: () => {
                this.onMinimized();
            },
            onFullscreen: () => {
                this.onFullscreen();
            },
            onBack: () => {
                if (!fullscreen) return;
                this.onMinimized();
            },
        };

        const hiddenStyle = {
            display: "none",
        };

        return (
            <div className="player">
                <div
                    className="player__fullscreen"
                    style={this.state.fullscreen ? {} : hiddenStyle}
                ></div>
                <div className="player__controls">
                    <div className="player__seeker">
                        {showControls && this.videoElement && (
                            <VideoSeeker
                                videoElement={this.videoElement}
                                onSeek={(value) => this.onSeekChange(value)}
                            />
                        )}
                    </div>
                    <Toolbar {...toolbarProps} />
                </div>
            </div>
        );
    }

    /**
     * Append videoElement to fullscreen wrapper element node.
     *
     * @private
     */
    private initVideoElement(videoElement?: HTMLVideoElement) {
        if (!videoElement) return;
        this.videoElement = videoElement;
        const fullscreenVideoContainer = document.querySelector(
            ".player__fullscreen"
        ) as HTMLDivElement;

        this.videoElement.onended = () => {
            this.handleVideoEnded();
        };

        this.videoElement.loop = false;
        this.videoElement.playsInline = true;
        this.videoElement.load();
        fullscreenVideoContainer.appendChild(videoElement);
    }

    private handleVideoEnded() {
        this.props.onVideoEnd?.();
        this.setState({playing: false});
        this.seekVideo(0, this.videoElement);
    }

    private onSeekChange(value: number) {
        this.seekVideo(value, this.videoElement);
    }

    private seekVideo(time: number, videoElement?: HTMLVideoElement) {
        if (videoElement) {
            videoElement.currentTime = time;
        }
    }

    private pauseVideo() {
        if (!this.videoElement || this.videoElement?.paused) return;
        this.setState({playing: false});
        this.videoElement.pause();
    }

    private playVideo() {
        if (!this.videoElement || !this.videoElement?.paused) return;
        this.setState({playing: true});
        this.videoElement.play();
        this.collectService?.collectVideoStart(this.props.activationId);

        // TODO: start playback counter
        this.startPlaybackDurationTracking()
    }

    /* ---- Handlers ---- */
    private onPause() {
        this.pauseVideo();

        if (this.state.fullscreen) return;
        this.props.onPause?.();
    }

    private onPlay() {
        if (!this.videoElement) return;
        console.log("===> onPlay", this.videoElement?.readyState);
        this.videoElement.muted = false;
        this.playVideo();

        if (this.state.fullscreen) return;
        this.props.onPlay?.();
    }

    private onFullscreen() {
        this.setState({
            fullscreen: true,
        });
    }

    private onMinimized() {
        this.pauseVideo();
        this.setState({fullscreen: false});
    }

    private startPlaybackDurationTracking() {
        let playedSeconds = 0;

        const playbackCounter = setInterval(() => {
            if (this.state.playing) {
                playedSeconds += 1;
            } else {
                this.collectService?.collectVideoStop(this.props.activationId, playedSeconds);
                playedSeconds = 0;
                clearInterval(playbackCounter);
            }
        }, 1000);
    }
}
