import { prependZeroes } from "./string.utils";

/**
 * Format duration in seconds to time representation of mm:ss
 *
 * @param value
 */
export const formatDuration: (value: number) => string = (value: number) => {
  const restSeconds = Math.floor(value % 60);
  const minutes = Math.floor(value / 60);
  return `${prependZeroes(minutes.toString(), 2)}:${prependZeroes(
    restSeconds.toString(),
    2
  )}`;
};
