import React, { FC } from 'react';
import { Progress } from '../Progress/Progress';
import './Loading.css';

type Props = {
    indetermined: boolean
}

export const Loading: FC<Props> = ({indetermined}) => {
    return (
        <div className="loading">
            <div className="loading__progress">
                <Progress indetermined={indetermined} max={100} value={0}/>
            </div>
            <div className="loading__message">Loading AR experience</div>
        </div>
    )
}
