import {environment} from "../environment/.env";
import {catchError, from, of} from "rxjs";
import {RetryService} from "./retry.service";

export class CollectService {
    public collectPageView(activationId: string) {
        this.collect(activationId, {
            tags: ["page", "view"],
        });
    }

    public collectVideoStart(activationId: string) {
        return this.collect(activationId, {
            tags: ["video", "start"],
            duration: 0,
        });
    }

    public collectVideoStop(activationId: string, duration: number) {
        return this.collect(activationId, {
            tags: ["video", "stop"],
            duration,
        });
    }

    public collectCtaManual(activationId: string) {
        return this.collect(activationId, {
            tags: ["cta", "manual"],
        });
    }

    public collectCtaAuto(activationId: string) {
        return this.collect(activationId, {
            tags: ["cta", "auto"],
        });
    }

    public collectFollow(activationId: string, onComplete?: () => void) {
        return this.collect(activationId, {
            tags: ["cta", "follow"],
        }, onComplete);
    }

    /**
     * Collect analytics event.
     * No return value, event will be handled internally.
     * Retry request until it's successful.
     *
     * @param productId
     * @param body
     * @param onComplete
     * @private
     */
    private collect(productId: string, body: unknown, onComplete?: () => void) {
        // Turned off to collect analytics on dev
        // if (!environment.production) {
        //   return of(null);
        // }
        const url = `${environment.analyticsURL}/products/${productId}/collect`;
        const headers = {
            authorization: `Basic ${environment.authToken}`,
            "Content-Type": "application/json",
        };

        const operation = from(
            fetch(url, {
                headers,
                credentials: "include",
                method: "POST",
                body: JSON.stringify(body),
            })
        ).pipe(
            catchError((err) => {
                console.log("Error fetching collect: ", err);
                return of(null);
            })
        );

        RetryService.instance.tryUntilSuccess(operation)
            .subscribe({
                complete: () => {
                    onComplete?.();
                }
            });
    }
}
