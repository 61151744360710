import { Slider } from "antd";
import React from "react";
import { formatDuration } from "../../../utils/time.utils";

type Props = {
  videoElement: HTMLVideoElement;
  onSeek: (time: number) => void
};

type State = {
  currentTime: number;
};

export class VideoSeeker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTime: 0,
    };
  }

  public componentDidMount(): void {
    this.initSeekerChange();
  }

  public render(): React.ReactNode {
    const { videoElement } = this.props;
    const { currentTime } = this.state;

    const formatter = (val?: number) => (val ? formatDuration(val ?? 0) : "-");
    return (
      <Slider
        defaultValue={0}
        value={currentTime ?? 0}
        step={0.01}
        min={0}
        max={videoElement?.duration ?? 0}
        tooltip={{ formatter }}
        onChange={(val) => this.onSeekChange(val)}
      />
    );
  }
  private onSeekChange(currentTime: number): void {
    this.props.onSeek(currentTime);
    // this.props.videoElement.currentTime = currentTime;
  }

  private initSeekerChange() {
    this.updateVideoTime();
  }

  private updateVideoTime() {
    requestAnimationFrame(() => {
      const currentTime = this.props.videoElement.currentTime ?? 0;
      this.setState({ currentTime });
      this.updateVideoTime();
    });
  }
}
