import React, { FC } from 'react';
import './Watermark.css';


const Watermark: FC = () => {
    return (
        <div className="watermark">
            <img className="watermark__img" src="images/watermark.png" alt="Videobomb Logo watermark"/>
        </div>
    )
}

export default Watermark;
