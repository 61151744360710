import { catchError, Observable, retry, throwError } from 'rxjs';

/**
 * Singleton retry service. Manages request queue and retry mechanism
 */
export class RetryService {
    private static _instance: RetryService;
    public static get instance() {
        if (!RetryService._instance) {
            RetryService._instance = new RetryService();
        }
        return RetryService._instance;
    }

    /**
     * Provided operation will be called until resulting observable not return success event.
     *
     * @param operation
     */
    public tryUntilSuccess(operation: Observable<unknown>) {
        return operation
            .pipe(
                catchError((error) => {
                    console.error('Error on retry:', error);
                    return throwError(() => error);
                }),
                retry({delay: 1000, count: 200})
            )
    }
}
