import React, {type FC} from 'react';
import './ToolbarControls.css';
import ToolbarButton from '../ToolbarButton/ToolbarButton';

export type Props = {
	isPlaying: boolean;
	isFullscreen: boolean;
	onPlay?: () => void;
	onPause?: () => void;
	onFullscreen?: () => void;
	onMinimize?: () => void;
	onBack?: () => void;
};

const ToolbarControls: FC<Props> = ({isPlaying, isFullscreen, onPlay, onFullscreen, onPause, onMinimize, onBack}) => (
	<div className='toolbar-controls'>
		<div className='toolbar-controls__left'>
			<ToolbarButton icon={'back'} label={'Back'} alt='Pause playback' onClick={() => onBack?.()}/>
		</div>
		<div className='toolbar-controls__playback'>
			{!isPlaying && <ToolbarButton icon={'play'} label={'Play'} onClick={() => onPlay?.()}/>}
			{isPlaying && <ToolbarButton icon={'pause'} label={'Pause'} onClick={() => onPause?.()}/>}
		</div>
		<div className='toolbar-controls__fullscreen'>
			{isFullscreen && <ToolbarButton icon={'minimize'} label={'Minimize'} onClick={() => onMinimize?.()}/>}
			{!isFullscreen && <ToolbarButton icon={'fullscreen'} label={'Fullscreen'} onClick={() => onFullscreen?.()}/>}
		</div>
	</div>
);

export default ToolbarControls;
