/**
 * Prepend zeroes to string.
 *
 * @param str
 * @param places
 */
export const prependZeroes: (str: string, places: number) => string = (
  str: string,
  places: number
) => {
  const prependPlaces = places - str.length;
  if (prependPlaces <= 0) {
    return str;
  }
  const prependSymbols = Array(prependPlaces).fill("0");
  return prependSymbols.join("") + str;
};
