export class PlatformService {
    /**
     * Check existence of header 'x-requested-with' in request, determining it's executed inside android web view.
     */
    public static checkAndroidWebView() {
        return fetch(`https://ety6gzpfj7.execute-api.us-east-1.amazonaws.com/web-view-check`, {
            method: 'POST',
            headers: {'content-type': 'application/json'}
        }).then(response => {
            return response.json();
        });
    }
}
