import React, {type FC} from 'react';
import './ToolbarButton.css';

type Props = {
	icon: string;
	label: string;
	alt?: string;
	onClick?: () => void;
};

const ToolbarButton: FC<Props> = ({label, icon, onClick}) => (
	<div className='toolbar-button clickable' onClick={() => onClick?.()}>
		<img
			className='icon'
			src={`./icons/${icon}.svg`}
			alt='Pause playback'
		/>
		<div className='label'>{label}</div>
	</div>
);

export default ToolbarButton;
