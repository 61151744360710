import React from 'react';
import './Progress.css';

type Props = {
    indetermined: boolean;
    max: number;
    value: number;
}

type State = {
    progress: number;
}

const randomNumber = (min: number, max: number) => {
    if (max < min) throw new Error('Max need to be lower than min');
    return Math.random() * (max - min) + min;
};

export class Progress extends React.Component<Props, State> {
    private _almostEnd = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            progress: 0
        }
    }

    componentDidMount() {
        if (this.props.indetermined) {
            this.resetProgress();
        } else {
            this.startProgress();
        }
    }

    public percentOfMax() {
        return (this.state.progress * 100) / this.props.max;
    }

    public getRandomProgress() {
        return Math.min(randomNumber(10, 30), this.almostEnd);
    }

    public getRandomTime() {
        return randomNumber(0.5, 1) * 1000;
    }

    private get almostEnd() {
        if (this._almostEnd) {
            return this._almostEnd;
        }
        this._almostEnd = this.getAlmostEnd();
        return this._almostEnd;
    }

    private getAlmostEnd() {
        return this.props.max * 0.9;
    }

    private resetProgress() {
        this.setState({progress: 10});
        // this.innerValue = 10;
    }

    private startProgress() {
        const rndTime = this.getRandomTime();
        setTimeout(() => {
            if (this.props.indetermined) {
                this.resetProgress();
                return;
            }
            const progress = this.getRandomProgress();
            if (progress + this.state.progress > this.almostEnd) {
                this.setState({progress: this.almostEnd})
                return;
            } else {
                this.setState({progress: this.state.progress + progress});
            }
            if (this.state.progress === this.props.max) {
                return;
            }
            this.startProgress();
        }, rndTime);
    }

    render() {
        const {indetermined} = this.props;

        const classes = indetermined ? 'progress__indetermined' : '';
        const style = {
            width: `${indetermined ? 30 : this.percentOfMax()}%`
        }
        return (
            <div className="progress">
                <div
                    className={['progress__bar', classes].join(' ')}
                    style={style}
                ></div>
            </div>

        )
    }
}
