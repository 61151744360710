import { PublicActivation } from '../model/Activation';
import { ThreeMarker, VideoFit } from 'tracking-engine-lib';

export const threeMarkerFromActivation = (res: PublicActivation): ThreeMarker => {
    const {marker, video, image, product, maskImage} = res;
    return {
        width: image.width,
        height: image.height,
        dpi: image.dpi ?? 300,
        url: marker,
        videoUrl: video,
        videoFit: (product?.meta?.videoAttributes?.objectFit ?? VideoFit.cover) as VideoFit,
        backgroundColor: product?.meta?.videoAttributes?.background,
        maskImageUrl: maskImage,
        bendMarkerDefinition: product?.meta?.curvedMarker,
    } satisfies ThreeMarker;
}
