import React, {type FC} from 'react';
import './Toolbar.css';
import ToolbarControls, {Props as ToolbarControlProps} from './ToolbarControls/ToolbarControls';

export type Props = ToolbarControlProps & {
    showControls: boolean;
    instructions?: string;
};

function parseNewLines(value: string) {
    return value.replace(/\n/g, '<br/>');
}

const Toolbar: FC<Props> = (props: Props) => (
    <div className={props.instructions && !props.showControls ? 'toolbar toolbar--instructions' : 'toolbar'}>
        {!props.showControls && props.instructions && <div className="toolbar__instructions">
            <div className='instructions__title'>
                Instructions:
            </div>
            <div dangerouslySetInnerHTML={{__html: parseNewLines(props.instructions)}}></div>
        </div>}
        {!props.instructions && !props.showControls && <div className='toolbar__content'>
            Center the image face in the camera
        </div>}
        {props.showControls && <ToolbarControls {...props}/>}
    </div>
);

export default Toolbar;
