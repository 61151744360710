import React from "react";
import "./App.css";
import InfoScreen from "./components/LoadingError/InfoScreen";
import {type Options, ThreeAREngine} from "tracking-engine-lib";
import {ActivationService} from "./services/activation.service";
import {VideoPlayer} from "./components/VideoPlayer/VideoPlayer";
import {PublicActivation} from "./model/Activation";
import {Loading} from "./components/Loading/Loading";
import {LinkOutlined} from "@ant-design/icons";
import {RedirectModal} from "./components/RedirectModal/RedirectModal";
import {threeMarkerFromActivation} from "./utils/threeMarker.utils";
import Watermark from "./components/Watermark/Watermark";
import {CollectService} from "./services/collect.service";
import {debounceTime, distinctUntilChanged, ReplaySubject, Subject,} from "rxjs";
import {PlatformService} from "./services/platform.service";

type ScreenOrientation = "landscape" | "portrait";

type State = {
    lastPlaybackState?: 'playing' | 'paused';
    incompatibleView?: boolean;
    activationUnavailable?: boolean;
    tracking?: boolean;
    loadingError?: boolean;
    markerLoadingError?: boolean;
    loading?: boolean;
    libLoaded: boolean;
    callToActionModal: boolean;
    redirecting: boolean;
    videoElement?: HTMLVideoElement;
    activation?: PublicActivation;
};

class App extends React.Component<unknown, State> {
    private activationId?: string;
    private playbackStarted?: Date;
    private readonly activationService = new ActivationService();
    private markerFound$ = new ReplaySubject<boolean>(1);
    /**
     * Reference to video element from library.
     * @private
     */
        // private videoElement?: HTMLVideoElement;
    private trackingOptions: Options = {
        markerNotFoundCb: () => {
            this.onMarkerLost();
        },
        markerFoundCb: () => {
            this.onMarkerFound();
        },
        endLoadingCb: (vs: HTMLVideoElement) => {
            // init
            this.onLibraryLoaded(vs);
            this.setState({loading: false});
        },
        libInitStarted: () => {
            this.setState({libLoaded: true});
        },
        libInitError: () => {
            this.setState({loadingError: true, loading: false});
        },
    };
    private collectService?: CollectService;
    private arEngine?: ThreeAREngine;

    constructor(props: unknown) {
        super(props);
        this.state = {
            redirecting: false,
            libLoaded: false,
            loading: true,
            markerLoadingError: false,
            callToActionModal: false,
            lastPlaybackState: 'paused'
        };

        this.markerFound$
            .pipe(distinctUntilChanged(), debounceTime(100))
            .subscribe((tracking) => {
                this.setState({tracking});
            });
    }

    public componentDidMount() {
        this.collectService = new CollectService();
        if (navigator.userAgent.includes("Android")) {
            PlatformService.checkAndroidWebView()
                .then((res) => {
                    if (res["web-view"]) {
                        this.setState({incompatibleView: true});
                    } else {
                        this.initData();
                    }
                })
                .catch((err) => {
                    console.error("ERROR", err);
                });
        } else if (navigator.userAgent.includes("Instagram")) {
            /* Workaround: iOS instagram webview scroll to remove bar overlapping toolbar  */
            window.scrollTo(0, 2000);
            this.initData();
        } else {
            this.initData();
        }
    }

    public async initData() {
        const idParam = this.getUrlParam("activation");
        if (!idParam) {
            this.setState({markerLoadingError: true});
            return;
        }
        this.activationId = idParam;
        try {
            this.collectService?.collectPageView?.(idParam);
        } catch (err) {
            console.error("collect failed", err);
        }
        const staticActivation = await this.activationService.getLocalActivation(
            this.activationId
        );
        if (staticActivation) {
            this.initTracking(staticActivation);
        } else {
            try {
                const activation = await this.activationService.getActivationData(
                    idParam
                );
                this.initTracking(activation);
                this.setState({activationUnavailable: false, loading: false});
            } catch (err) {
                console.error("error loading activation", err);
                this.setState({activationUnavailable: true, loading: false});
            }
        }
    }

    public render() {
        const {
            loadingError,
            tracking,
            libLoaded,
            loading,
            markerLoadingError,
            activation,
            callToActionModal,
            videoElement,
            activationUnavailable,
            incompatibleView,
            lastPlaybackState,
            redirecting
        } = this.state;

        const showMarkerWatermark = activation?.image?.url && !tracking;
        const showRedirectHeader = activation?.product?.meta?.redirect;

        const isPlaying = tracking && lastPlaybackState === 'playing';
        return (
            <div className="app">
                {showMarkerWatermark && (
                    <div className="app__marker-watermark">
                        <img
                            src={activation?.previewImage ?? activation?.image?.url}
                            alt={"marker image for orientation"}
                        />
                    </div>
                )}
                <Watermark/>
                {loadingError && (
                    <InfoScreen
                        title={"Error getting permissions"}
                        message={
                            "Grant permissions to use camera to browser in phone settings and reload page"
                        }
                    />
                )}
                {markerLoadingError && (
                    <InfoScreen
                        title={"Error loading activation"}
                        message={"Activation is not present or activation data are invalid"}
                    />
                )}
                {activationUnavailable && (
                    <InfoScreen
                        title={"Oops! This activation is no longer available"}
                        link={{label: "videobomb.com", url: "https://videobomb.co"}}
                    />
                )}

                {incompatibleView && (
                    <InfoScreen
                        image={"images/open-browser.svg"}
                        title={"Enjoy the full AR experience "}
                        message={
                            "It's recommended that you open the app in your default browser by clicking on this button in the top right corner."
                        }
                    />
                )}

                {showRedirectHeader && (
                    <div className="app__header">
                        <div
                            className="app__header__action clickable"
                            onClick={() => this.onRedirectClick()}
                        >
                            <LinkOutlined className={"app__header__action"}/>
                        </div>
                    </div>
                )}
                {
                    this.activationId && <VideoPlayer
                        activationId={this.activationId}
                        videoElement={videoElement}
                        isPlaying={isPlaying}
                        showControls={tracking}
                        onVideoEnd={() => this.onVideoEnded()}
                        onPlay={() => this.onVideoPlay()}
                        onPause={() => this.onVideoPause()}
                        instructions={activation?.product?.meta?.customInstructions}
                    />
                }

                <div id="container"></div>

                {loading && !libLoaded && <Loading indetermined={true}/>}
                {loading && libLoaded && <Loading indetermined={false}/>}
                <RedirectModal
                    content={activation?.product?.meta?.redirect?.description ?? ""}
                    buttonText={activation?.product?.meta?.redirect?.button ?? ""}
                    title={activation?.product?.meta?.redirect?.title ?? ""}
                    imageUrl={activation?.redirectImage ?? ""}
                    redirectUrl={activation?.product?.meta?.redirect?.url ?? ""}
                    onCancel={() => this.onCallToActionCancel()}
                    onRedirect={() => this.onCallToActionRedirect()}
                    open={callToActionModal}
                    loading={redirecting}
                />
            </div>
        );
    }

    private onVideoPlay() {
        if (!this.activationId) return;
        if (!this.playbackStarted) {
            // this.collectService?.collectVideoStart?.(this.activationId);
            this.playbackStarted = new Date();
        }
        this.setState({lastPlaybackState: 'playing'})
    }

    private onVideoPause() {
        const {videoElement} = this.state;
        if (!this.activationId || !videoElement?.currentTime) return;
        this.setState({lastPlaybackState: 'paused'})
    }

    private onVideoEnded() {
        if (this.state.activation?.product?.meta?.redirect) {
            this.openCallToAction();
            if (!this.activationId) return;
            this.collectService?.collectCtaAuto(this.activationId);
        }
        this.setState({lastPlaybackState: 'paused'})
    }

    private getUrlParam(param: string): string | null {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has(param)) {
            console.error(`Missing ${param} parameter`);
            return null;
        }
        return urlParams.get(param);
    }

    private initTracking(activation?: PublicActivation) {
        const kalman = undefined;
        const level = undefined;

        if (!activation) {
            console.error("no activation available");
            return;
        }

        this.setState({activation});
        const threeMarker = threeMarkerFromActivation(activation);
        console.log({threeMarker, activation});
        const container = document.getElementById("container") as HTMLDivElement;
        if (!container) {
            console.error("CONTAINER DOESNT EXIST");
            return;
        }

        this.arEngine = new ThreeAREngine(
            threeMarker,
            container,
            this.trackingOptions,
            level,
            kalman
        );
        this.arEngine.start();
    }

    private onCallToActionCancel() {
        this.setState({callToActionModal: false});
    }

    private onCallToActionRedirect() {
        if (!this.activationId) return;
        this.setState({redirecting: true})
        this.collectService?.collectFollow(this.activationId, () => {
            const redirectUrl = this.state.activation?.product?.meta?.redirect?.url
            window.open(`https://${redirectUrl}`, '_self');
        })
    }

    private onRedirectClick() {
        this.openCallToAction();
        if (!this.activationId) return;
        this.collectService?.collectCtaManual(this.activationId);
    }

    private openCallToAction() {
        this.setState({callToActionModal: true});
    }

    private onMarkerLost() {
        this.markerFound$.next(false);
    }

    private onMarkerFound() {
        this.markerFound$.next(true);
    }

    private onLibraryLoaded(videoEl: HTMLVideoElement) {
        const {videoElement} = this.state;
        videoEl.muted = true;
        videoEl.playsInline = true;
        if (videoElement?.src !== videoEl.src) {
            this.setState({videoElement: videoEl});
        }
        this.initScreeRotation();
    }

    private initScreeRotation() {
        const resized$ = new Subject<ScreenOrientation>();
        window.onresize = () => {
            const {innerHeight, innerWidth} = window;
            const orientation: ScreenOrientation =
                innerWidth > innerHeight ? "landscape" : "portrait";
            resized$.next(orientation);
        };

        resized$
            .pipe(debounceTime(100), distinctUntilChanged())
            .subscribe((orientation: ScreenOrientation) => {
                console.log({orientation});
                this.reInitArScreens();
            });
    }

    private reInitArScreens() {
        this.arEngine?.onOrientationChanged?.();
    }
}

export default App;
