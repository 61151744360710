import React from 'react';
import './RedirectModal.css';
import {Button, Modal} from 'antd';

type Props = {
    title: string;
    content: string;
    imageUrl?: string;
    buttonText: string;
    redirectUrl: string;
    onCancel: () => void;
    onRedirect: () => void;
    open: boolean;
    loading: boolean;
}

export class RedirectModal extends React.Component<Props, unknown> {
    render() {
        const {title, content, imageUrl, buttonText, loading} = this.props;
        return (
            <Modal open={this.props.open} footer={null} closable={true} onCancel={() => this.props.onCancel()}>
                <div className="call-to-action">
                    <div className="content">
                        <div className="title">{title}</div>
                        <div className="description">{content}</div>
                    </div>
                    {imageUrl && <img
                        src={imageUrl}
                        alt={'Redirect image'}
                        loading={'eager'}
                    />}
                    <Button className="call-to-action__button" size={'large'} onClick={() => this.onRedirectClick()}
                            loading={loading}>
                        {buttonText}
                    </Button>
                </div>
            </Modal>
        );
    }

    private onRedirectClick() {
        if (this.props.loading) {
            return;
        }
        this.props.onRedirect();
    }
}
